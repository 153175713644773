import RouteConfigContext from '@components/Router/RouteConfigContext';
import {
  Route
  //, useLocation, useParams, useRouteMatch
} from 'react-router';
import React from 'react';

// TO-DO .. base is where all route data fetching is handled...
export const Base = ({ children, fromStateToken }) => {
  return children;
};

const SessionTokenRouteBase = ({ children, route }) => (
  <Route {...route}>
    <RouteConfigContext.Provider value={route}>
      {/* <LocationListener /> */}
      <Base>{children}</Base>
    </RouteConfigContext.Provider>
  </Route>
);

export default SessionTokenRouteBase;
