// /* eslint-disable sort-keys */
import { textSpecs } from './textSpecs';

import {
  FEEDBACK_GREEN_C000,
  FEEDBACK_GREEN_C100,
  FEEDBACK_GREY_C000,
  FEEDBACK_GREY_C100,
  FEEDBACK_RED_C000,
  FEEDBACK_RED_C100,
  FEEDBACK_WHITE_C000,
  GREYSCALE_C000,
  GREYSCALE_C100,
  GREYSCALE_C200,
  GREYSCALE_C300,
  GREYSCALE_C400,
  GREYSCALE_C500,
  GREYSCALE_C600,
  GREYSCALE_C700,
  GREYSCALE_C800,
  TRANSPARENT
} from '@themes/constants/colors';

import NONE from '@themes/constants/css-none';
import { FONT_SIZE_BASE } from '@themes/constants/typography';
// import { transparentize } from 'polished';

// export const borders = [0, '1px solid', '2px solid'];

export const Border = {
  None: 0,
  Thin: 1,
  Thick: 2
};

// // export const borderSpacing = [0, '1px', '2px'];
// export const borderSpacing = [0, 0, 0];

export const BorderSpacing = {
  None: 0,
  Thin: 1,
  Thick: 2
};

export const breakpoints = ['48rem', '64rem', '80rem']; // 768px, 1024px, 1280px

export const Breakpoint = {
  Small: 0,
  Medium: 1,
  Large: 2,
  ExtraLarge: 3
};

const colors = {
  brand: [],
  greyscale: [
    GREYSCALE_C000,
    GREYSCALE_C100,
    GREYSCALE_C200,
    GREYSCALE_C300,
    GREYSCALE_C400,
    GREYSCALE_C500,
    GREYSCALE_C600,
    GREYSCALE_C700,
    GREYSCALE_C800
  ],
  feedback: {
    GREY_C000: FEEDBACK_GREY_C000,
    GREY_C100: FEEDBACK_GREY_C100,
    RED_C000: FEEDBACK_RED_C000,
    RED_C100: FEEDBACK_RED_C100,
    GREEN_C000: FEEDBACK_GREEN_C000,
    GREEN_C100: FEEDBACK_GREEN_C100,
    WHITE_C000: FEEDBACK_WHITE_C000
  },
  transparent: TRANSPARENT
};

export const Color = {
  brand: {
    C000: 'brand.0',
    C100: 'brand.1',
    C200: 'brand.2',
    C300: 'brand.3',
    C400: 'brand.4',
    C500: 'brand.5',
    C600: 'brand.6',
    C700: 'brand.7',
    C800: 'brand.8'
  },
  greyscale: {
    C000: 'greyscale.0',
    C100: 'greyscale.1',
    C200: 'greyscale.2',
    C300: 'greyscale.3',
    C400: 'greyscale.4',
    C500: 'greyscale.5',
    C600: 'greyscale.6',
    C700: 'greyscale.7',
    C800: 'greyscale.8'
  },
  feedback: {
    GREY_C000: 'feedback.GREY_C000',
    GREY_C100: 'feedback.GREY_C100',
    RED_C000: 'feedback.RED_C000',
    RED_C100: 'feedback.RED_C100',
    GREEN_C000: 'feedback.GREEN_C000',
    GREEN_C100: 'feedback.GREEN_C100',
    WHITE_C000: 'feedback.WHITE_C000'
  },
  transparent: 'transparent'
};

// const cursors = ['auto', 'pointer'];

export const Cursor = {
  Auto: 0,
  Pointer: 1
};

export const fontFamilies = {
  Graphik: ['"Graphik Web"', 'Helvetica', 'sans-serif'].join(', '),
  Mono: [
    '"Operator Mono"',
    '"Fira Code Retina"',
    '"Fira Code"',
    '"FiraCode-Retina"',
    '"Andale Mono"',
    '"Lucida Console"',
    'Consolas',
    'Monaco',
    'monospace'
  ].join(', ')
};

// theme.fonts
export const FontFamily = {
  Mono: 'Mono',
  SansSerif: 'Graphik'
};

// const fontWeights = {
//   medium: 500,
//   regular: 400
// };

export const FontWeight = {
  Medium: 'medium',
  Regular: 'regular'
};

// const { fontSizes, letterSpacings, lineHeights, textSpecKeys } = Object.entries(textSpecs).reduce(
//   (acc, [key, { fontSize, letterSpacing, lineHeight }]) => {
//     acc.fontSizes.push(fontSize);
//     acc.letterSpacings.push(letterSpacing);
//     acc.lineHeights.push(lineHeight);
//     acc.textSpecKeys[key] = key;

//     return acc;
//   },
//   { fontSizes: [], letterSpacings: [], lineHeights: [], textSpecKeys: {} }
// );

// export const TextSpec = textSpecKeys;

// const listStyles = ['circle', 'decimal', 'disc', NONE, 'square'];

export const ListStyle = {
  Circle: 0,
  Decimal: 1,
  Disc: 2,
  None: 3,
  Square: 4
};

// const maxWidths = ['100%', '48rem', '64rem', '80rem']; // 100%, 768px, 1024px, 1280px

export const MaxWidth = {
  Small: 0,
  Medium: 1,
  Large: 2,
  ExtraLarge: 3
};

// const outlines = [NONE];

export const Outline = {
  None: 0
};

// export const radii = [0, 2, 4, 8, 10000, '50%'];

export const Radius = {
  Square: 0,
  Small: 1,
  Medium: 2,
  Large: 3,
  Pill: 4,
  Round: 5
};

// // X Y BR SP COLOR
// const shadows = [
//   NONE,
//   `0 1px 1px 0 ${transparentize(0.9, GREYSCALE_C000)}, 0 2px 1px -1px ${transparentize(
//     0.96,
//     GREYSCALE_C000
//   )}, 0 2px 1px -1px ${transparentize(0.92, GREYSCALE_C000)}`,
//   `0 4px 5px 0 ${transparentize(0.93, GREYSCALE_C000)}, 0 3px 15px 0 ${transparentize(
//     0.92,
//     GREYSCALE_C000
//   )}, 0 2px 4px -1px ${transparentize(0.88, GREYSCALE_C000)}`,
//   `0 12px 17px 2px ${transparentize(0.94, GREYSCALE_C000)}, 0 5px 22px 4px ${transparentize(
//     0.94,
//     GREYSCALE_C000
//   )}, 0 7px 8px -4px ${transparentize(0.9, GREYSCALE_C000)}`,
//   `0 24px 24px 12px ${transparentize(0.9, GREYSCALE_C000)}`,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE,
//   NONE
// ];

export const Shadow = {
  None: 0,
  Small: 1,
  Medium: 2,
  Large: 3,
  ExtraLarge: 4
};

// const space = [];

export const Space = {
  None: 0,
  Down3: '0.125rem',
  Down2: '0.25rem',
  Down1: '0.5rem',
  Base: '1rem',
  Up1: '1.5rem',
  Up2: '2rem',
  Up3: '2.5rem',
  Up4: ['3rem', null, null, '3.5rem'],
  Up5: ['3.5rem', '4rem', '4.5rem', '5rem'],
  Up6: ['4.5rem', '5.5rem', '6rem', '7rem'],
  Up7: ['6rem', '7rem', '8.5rem', '9.5rem']
};

// const textStyles = {
//   caps: {
//     letterSpacing: '0.1em',
//     textTransform: 'uppercase'
//   },
//   none: {
//     textDecoration: NONE
//   },
//   underline: {
//     textDecoration: 'underline'
//   }
// };

export const TextStyle = {
  Caps: 'caps',
  None: NONE,
  Underline: 'underline'
};

const userSelects = ['auto', NONE];

export const UserSelect = {
  Auto: 0,
  None: 1
};

export const viewports = ['sm', 'md', 'lg', 'xl'];

export const Viewport = {
  Small: viewports[0],
  Medium: viewports[1],
  Large: viewports[2],
  ExtraLarge: viewports[3]
};

const whiteSpaces = ['normal', 'nowrap', 'pre', 'pre-wrap'];

export const WhiteSpace = {
  Normal: 0,
  NoWrap: 1,
  Pre: 2,
  PreWrap: 3
};

export const BASE_THEME_MUI = {
  // breakpoints,
  palette: {
    action: {
      action: GREYSCALE_C700,
      hover: GREYSCALE_C700
    },
    divider: GREYSCALE_C600,
    error: {
      dark: FEEDBACK_RED_C000,
      light: FEEDBACK_RED_C000,
      main: FEEDBACK_RED_C000
    }
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: FONT_SIZE_BASE,
    useNextVariants: true
  },
  viewports
};

export const BASE_THEME_STYLED = {
  // borders,
  // borderSpacing,
  // breakpoints,
  // colors,
  // cursors,
  fonts: fontFamilies,
  // fontSizes,
  // fontWeights,
  // letterSpacings,
  // lineHeights,
  // listStyles,
  // maxWidths,
  // outlines,
  // radii,
  // shadows,
  // space,
  // textSpecs,
  // textStyles,
  // userSelects,
  // viewports,
  // whiteSpaces
};

export const Align = {
  Bottom: 'bottom',
  Center: 'center',
  Hidden: 'hidden',
  Left: 'left',
  Right: 'right',
  Top: 'top'
};

export const AnchorStyle = {
  Icon: 'Icon',
  LargePrimary: 'LargePrimary',
  Primary: 'Primary',
  Secondary: 'Secondary',
  TextLink: 'TextLink',
  Unstyled: 'Unstyled'
};

export const ButtonStyle = {
  Icon: 'Icon',
  LargePrimary: 'LargePrimary',
  Primary: 'Primary',
  Secondary: 'Secondary',
  TextLink: 'TextLink',
  Unstyled: 'Unstyled'
};

export const FillStyle = {
  Fill: 'Fill',
  Outline: 'Outline'
};

export const InputSize = {
  Large: 'large',
  Small: 'small'
};

export const TransitionTime = '200ms';

export const ZIndex = {
  Down1: -1, // Hidden
  None: 0, // Body
  Up1: 1, // Sticky Page Elements
  Up2: 2, // Dropdowns, Modals, Popups
  Up3: 3, // Sub-navigation
  Up4: 4, // Summary
  Up5: 5, // Navigation
  Up6: 6 // Header
};
