// import { useCheckRoutePermissions } from '.../shared/utils/util-route-permissions';
import SessionTokenRouteBase from '@components/Router/SessionTokenRoute/Base';
import PATHS from '@components/Router/paths';
import Redirect from '@components/Router/Redirect';
import { useSelector } from 'react-redux';
import React from 'react';

const Default = ({ children }) => {
  const loading = useSelector((state) => state.Auth.loading)
  const loggedIn = useSelector((state) => state.Auth.loggedIn)
  const refreshToken = localStorage.getItem('refreshToken');
  const expiresAt = localStorage.getItem('expiresAt');

  const checkRoutePermissions = () => {
    // TODO -- add acl permissions per route based on userType
    // const checkRoutePermissions = useCheckRoutePermissions();
    return loggedIn ? true : false;
  };

  if (checkRoutePermissions()) {
    return children;
  }

  // show a loading screen while we check auth
  if (!loading && (refreshToken && expiresAt > Date.now())) {
    return <Redirect to={PATHS.LOADING} />;
  }

  // get outta here
  return <Redirect to={PATHS.USER_LOGIN} />;
};

const SessionTokenRouteDefault = ({ children, route }) => (
  <SessionTokenRouteBase route={route}>
    <Default>{children}</Default>
  </SessionTokenRouteBase>
);

export default SessionTokenRouteDefault;
