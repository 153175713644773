
// NOTE: these libs are redundant if using redux-saga-firebase
// HOLD FOR NOW IN CASE redux-saga-firebase is insufficant for my needs

// import { firebaseReducer as firebase } from 'react-redux-firebase'
// import { firestoreReducer } from 'redux-firestore'
// import { persistReducer } from 'redux-persist'
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
// import localStorage from 'redux-persist/lib/storage'

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './auth/reducer';
import App from './app/reducer';
import Places from './places/reducer';
import Users from './users/reducer';
import Admin from './admin/reducers'
import Storage from './storage/reducers';

const appReducer = (history) =>
  combineReducers({
    App,
    Auth,
    Places,
    Users,
    Admin,
    Storage,
    // firebase: persistReducer(
    //   {
    //     key: 'firebaseState',
    //     storage: localStorage,
    //     stateReconciler: hardSet,
    //     blacklist: ['Places']
    //   },
    //   firebase,
    // ),
    // firestore: firestoreReducer,
    router: connectRouter(history)
  });

const rootReducer = (history) => {
  // TODO - clear persisted local store on logout ..
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  // https://stackoverflow.com/questions/54199540/resetting-redux-state-on-logout
  // https://github.com/rt2zz/redux-persist

  return appReducer(history)
}
export default rootReducer;
