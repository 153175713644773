import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import {
  PLACES_COLLECTION,
  USERS_COLLECTION
} from '@lifetrip/services/firebase';
import { rsf } from '@lifetrip/services/firebase';
import { slugify } from '@lifetrip/utils/slugify';

import {
  clearForm,
  syncPlacesSuccess,
  updateStatus,
  PLACES__ADD_REQUEST,
  PLACES__DELETE_REQUEST,
  PLACES__UPDATE_REQUEST,
  PLACES__SYNC,
  STATUS
} from './actions';

/**
 * Parse the Firebase respone; we only need the data and an Id
 */
const documentTransformer = docs => {
  const response = []
  docs.forEach(doc =>
    response.push({
      id: doc.id,
      ...doc.data(),
    }),
  )
  return response
}

/**
 * Sync
 */
function* syncPlacesSaga({ uid }) {
  const COLLECTION = `${USERS_COLLECTION}/${uid}/${PLACES_COLLECTION}`;
  yield fork(
    rsf.firestore.syncCollection,
    COLLECTION,
    {
      successActionCreator: syncPlacesSuccess,
      transform: documentTransformer,
    }
  )
}

/**
 * Saves Geocoder data from a location search to a users places collection
 * @param {*} param0
 */
function* addUserPlace({
  context,
  coordinates,
  id,
  place_name,
  place_type,
  text,
  uid
}) {
  try {
    const COLLECTION = `${USERS_COLLECTION}/${uid}/${PLACES_COLLECTION}`;
    yield call(
      rsf.firestore.addDocument,
      COLLECTION,
      {
        createdAt: Date.now(),
        status: STATUS.NEW,
        slug: slugify(text.toString()),
        locationName: text.toString(),
        placeName: place_name.toString(),
        placeType: place_type,
        placeId: id,
        uid, // userId (saved for redundancy / migration)
        context,
        coordinates
      }
    );

    yield all([
      put(updateStatus(PLACES__ADD_REQUEST, STATUS.DONE)),
      put(clearForm())
    ])

  } catch (error) {
    // TODO - HANDLE ERRORs
    console.log(`[error] | addNewPlace | ${error}`)
  }
}


/**
 * SAVE -- TBD -- admins can add places/locations to the global dataset
 * TODO - check for unique slug
 * @param {*} param0
 */
function* addNewPlace({ locationName, latitude, longitude, uid }) {
  try {
    const COLLECTION = `${USERS_COLLECTION}/${uid}/${PLACES_COLLECTION}`;
    yield call(
      rsf.firestore.addDocument,
      COLLECTION,
      {
        createdAt: Date.now(),
        slug: slugify(locationName.toString()),
        locationName: locationName.toString(),
        latitude: latitude.toString(),
        longitude: longitude.toString()
      }
    );

    // TODO - save for global places
    // yield call(
    //   rsf.firestore.addDocument,
    //   PLACES_COLLECTION,
    //   {
    //     createdBy: userId,
    //     createdAt: Date.now(),
    //     slug: slugify(locationName.toString()),
    //     locationName: locationName.toString(),
    //     latitude: latitude.toString(),
    //     longitude: longitude.toString()
    //   }
    // );

    yield all([
      put(updateStatus(PLACES__ADD_REQUEST, STATUS.DONE)),
      put(clearForm())
    ])

  } catch (error) {
    // TODO - HANDLE ERRORs
    console.log(`[error] | addNewPlace | ${error}`)
  }
}


/**
 *  Removes a place document from users places collection
 * @param {*} param0
 */
function* deleteUserPlace({ id, uid }) {
  try {
    const DOCUMENT = `${USERS_COLLECTION}/${uid}/${PLACES_COLLECTION}/${id}`;
    yield call(
      rsf.firestore.deleteDocument,
      DOCUMENT
    )
    yield put(updateStatus(PLACES__DELETE_REQUEST, STATUS.DONE))
  } catch (error) {
    console.log('[error]', error)
    // TODO - HANDLE ERRORs
  }
}


/**
 *
 * @param {*} param0
 */
function* deletePlace({ id }) {
  yield console.log('del', id)
  // try {
  //   yield call(
  //     rsf.firestore.deleteDocument,
  //     `${PLACES_COLLECTION}/${id}`
  //   )
  //   yield put(updateStatus(PLACES__DELETE_REQUEST, STATUS.DONE))
  // } catch (error) {
  //   // TODO - HANDLE ERRORs
  // }
}

/**
 *
 * @param {*} param0
 */
function* editPlace({ payload }) {
  const { id, locationName, latitude, longitude } = payload
  try {
    yield call(
      rsf.firestore.updateDocument,
      `${PLACES_COLLECTION}/${id}`,
      {
        locationName,
        latitude,
        longitude,
        lastUpdated: Date.now()
      }
    )
    yield all([
      put(updateStatus(PLACES__UPDATE_REQUEST, STATUS.DONE)),
      put(clearForm())
    ])
  } catch (error) {
    // TODO - HANDLE ERRORs
  }
}

export default function* rootSaga() {
  yield all([
    // fork(syncPlacesSaga),
    takeEvery(PLACES__SYNC, syncPlacesSaga),
    takeEvery(PLACES__ADD_REQUEST, addUserPlace),
    // takeEvery(PLACES__ADD_REQUEST, addNewPlace),
    // takeEvery(PLACES__DELETE_REQUEST, deletePlace),
    takeEvery(PLACES__DELETE_REQUEST, deleteUserPlace),
    takeEvery(PLACES__UPDATE_REQUEST, editPlace),
  ]);
}
