import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/performance';
import ReduxSagaFirebase from 'redux-saga-firebase'

export const USERS_COLLECTION = 'users';
export const PLACES_COLLECTION = 'places'

// load keys from enn process
const {
  // NODE_ENV,
  REACT_APP_FIREBASE_apiKey,
  REACT_APP_FIREBASE_authDomain,
  REACT_APP_FIREBASE_databaseURL,
  REACT_APP_FIREBASE_projectId,
  REACT_APP_FIREBASE_storageBucket,
  REACT_APP_FIREBASE_messagingSenderId,
  REACT_APP_FIREBASE_appId,
  REACT_APP_FIREBASE_measurementId
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_apiKey,
  authDomain: REACT_APP_FIREBASE_authDomain,
  databaseURL: REACT_APP_FIREBASE_databaseURL,
  projectId: REACT_APP_FIREBASE_projectId,
  storageBucket: REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: REACT_APP_FIREBASE_messagingSenderId,
  appId: REACT_APP_FIREBASE_appId,
  measurementId: REACT_APP_FIREBASE_measurementId
};

let firebaseApp = {};
let perf = {};
let analytics = {};
let db = {};
let rsf = {};
let storage = {};

// hot re-load check -- make sure we don't already have an instance of firebase
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
  rsf = new ReduxSagaFirebase(firebaseApp)
  storage = firebase.storage;
  // https://firebase.google.com/docs/firestore/manage-data/enable-offline
  // db = firebase.firestore().enablePersistence({
  //   synchronizeTabs: true
  // }).catch((err) => {
  //   if (err.code === 'failed-precondition') {
  //     console.error('failed-precondition');
  //     // Multiple tabs open, persistence can only be enabled
  //     // in one tab at a a time.
  //     // ...
  //   } else if (err.code === 'unimplemented') {
  //     console.error('unimplemented feature');
  //     // The current browser does not support all of the
  //     // features required to enable persistence
  //     // ...
  //   }
  // });

  // analytics = firebase.analytics();
  // perf = firebase.performance();
  // console.log('firebase loaded ..')
  // firebase.analytics().logEvent(`SYSTEM ONLINE : [${NODE_ENV}]`);
}


export {
  analytics,
  db,
  firebaseApp,
  perf,
  rsf,
  storage
};

export default firebaseApp;
