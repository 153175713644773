import AsyncComponent from '@components/AsyncComponent';
import SessionTokenRouteDefault from '@components/Router/SessionTokenRoute/Default';
import PublicRoute from '@components/Router/PublicRoute';

const ROUTES = [
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Admin'),
    exact: true,
    metadata: {
      description: 'Admin',
      title: 'admin'
    },
    path: '/admin',
    type: SessionTokenRouteDefault
  },
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Loading'),
    exact: true,
    metadata: {
      description: 'Members Only',
      title: 'Loading'
    },
    path: '/loading',
    type: PublicRoute
  },
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Login'),
    exact: true,
    metadata: {
      description: 'Members Only',
      title: 'Login'
    },
    path: '/login',
    type: PublicRoute
  },
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Dashboard'),
    exact: true,
    metadata: {
      description: 'Dashboard',
      title: 'lifetrip | Dashboard'
    },
    path: '/dashboard',
    type: SessionTokenRouteDefault
  },
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Home'),
    exact: true,
    metadata: {
      description: 'Home',
      title: 'lifetrip'
    },
    path: '/',
    type: SessionTokenRouteDefault
  },
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Profile'),
    exact: true,
    metadata: {
      description: 'Profile',
      title: 'lifetrip'
    },
    path: '/profile',
    type: SessionTokenRouteDefault
  },

  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Profile'),
    exact: true,
    metadata: {
      description: 'Profile',
      title: 'lifetrip'
    },
    path: '/',
    type: SessionTokenRouteDefault
  },

  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Signup'),
    exact: true,
    metadata: {
      description: 'Signup',
      title: 'lifetrip'
    },
    path: '/signup',
    type: PublicRoute
  },


  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Settings'),
    exact: true,
    metadata: {
      description: 'settings',
      title: 'lifetrip | settings'
    },
    path: '/settings',
    type: SessionTokenRouteDefault
  },

  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Photos'),
    exact: true,
    metadata: {
      description: 'photos',
      title: 'lifetrip | photos'
    },
    path: '/photos',
    type: SessionTokenRouteDefault
  },

  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Places'),
    exact: true,
    metadata: {
      description: 'your places',
      title: 'lifetrip | your places'
    },
    path: '/places',
    type: SessionTokenRouteDefault
  },

  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Place'),
    exact: true,
    metadata: {
      description: 'Location',
      title: 'lifetrip | location'
    },
    path: '/:slug/:userId',
    type: SessionTokenRouteDefault
  },

  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Trips'),
    exact: true,
    metadata: {
      description: 'settings',
      title: 'lifetrip | trips'
    },
    path: '/trips',
    type: SessionTokenRouteDefault
  },


  {
    component: AsyncComponent,
    componentResolver: () => import('@views/Activities'),
    exact: true,
    metadata: {
      description: 'activities',
      title: 'lifetrip | activities'
    },
    path: '/activities',
    type: SessionTokenRouteDefault
  },


  // TODO - HOLD GOOGLE MAP FOR LATER (POV-WIP)
  {
    component: AsyncComponent,
    componentResolver: () => import('@views/GoogleMap'),
    exact: true,
    metadata: {
      description: 'GoogleMap',
      title: 'lifetrip | GoogleMap'
    },
    path: '/gmap',
    type: SessionTokenRouteDefault
  },
];

export default ROUTES;
