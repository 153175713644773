export const ADMIN__ROLE_UPDATE = 'ADMIN__ROLE_UPDATE';
export const ADMIN__ROLE_UPDATE_SUCCESS = 'ADMIN__ROLE_UPDATE_SUCCESS';

export const updateRole = (email) => ({
  type: ADMIN__ROLE_UPDATE,
  email
});

export const updateRoleSuccess = () => ({
  type: ADMIN__ROLE_UPDATE_SUCCESS
})
