// import { REGISTER } from "redux-persist";

// export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const LOGIN__REQUEST = 'LOGIN__REQUEST';
export const LOGIN__SUCCESS = 'LOGIN__SUCCESS';
export const LOGIN__FAILURE = 'LOGIN__FAILURE';
export const LOGOUT__REQUEST = 'LOGOUT__REQUEST';
export const LOGOUT__SUCCESS = 'LOGOUT__SUCCESS';
export const LOGOUT__FAILURE = 'LOGOUT__FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER__SUCCESS = 'REGISTER__SUCCESS';
export const REGISTER__FAILURE = 'REGISTER__FAILURE';
export const AUTH__UPDATE_PROFILE = 'AUTH__UPDATE_PROFILE';
export const AUTH__UPDATE_PROFILE_SUCCESS = 'AUTH__UPDATE_PROFILE_SUCCESS';

export const updateProfileRequest = (payload) => ({
  type: AUTH__UPDATE_PROFILE,
  payload
});

export const updateProfileRequestSuccess = (payload) => ({
  type: AUTH__UPDATE_PROFILE_SUCCESS,
  ...payload
})

export const login = (payload) => ({
  type: LOGIN__REQUEST,
  payload
});

export const loginSuccess = (user) => ({
  type: LOGIN__SUCCESS,
  user
});

export const loginFailure = (error) => ({
  type: LOGIN__FAILURE,
  error
});

export const logout = () => ({
  type: LOGOUT__REQUEST
});

export const logoutSuccess = () => ({
  type: LOGOUT__SUCCESS
});

export const logoutFailure = (error) => ({
  type: LOGOUT__FAILURE,
  error
});

export const register = (payload) => ({
  type: REGISTER_REQUEST,
  payload
});

export const registerFailure = (error) => ({
  type: REGISTER__FAILURE,
  error
});

export const createUserSuccess = () => ({
  type: REGISTER__SUCCESS
})
