export const LIFETRIP_BRAND_C000 = '#000000';
export const LIFETRIP_BRAND_C100 = '#232323';
export const LIFETRIP_BRAND_C200 = '#232323';
export const LIFETRIP_BRAND_C300 = '#232323';
export const LIFETRIP_BRAND_C400 = '#232323';
export const LIFETRIP_BRAND_C500 = '#A3A3A3';
export const LIFETRIP_BRAND_C600 = '#DEDEDE';
export const LIFETRIP_BRAND_C700 = '#F8F8F8';
export const LIFETRIP_BRAND_C800 = '#FFFFFF';

export const GREYSCALE_C000 = '#000000';
export const GREYSCALE_C100 = '#232323';
export const GREYSCALE_C200 = '#474747';
export const GREYSCALE_C300 = '#474747';
export const GREYSCALE_C400 = '#6D6D6D';
export const GREYSCALE_C500 = '#A3A3A3';
export const GREYSCALE_C600 = '#DEDEDE';
export const GREYSCALE_C700 = '#F8F8F8';
export const GREYSCALE_C800 = '#FFFFFF';

export const TRANSPARENT = 'rgba(255, 255, 255, 0)';

export const FEEDBACK_GREEN_C000 = '#2BA87A';
export const FEEDBACK_GREEN_C100 = '#31C68E';
export const FEEDBACK_GREY_C000 = '#474747';
export const FEEDBACK_GREY_C100 = '#DEDEDE';
export const FEEDBACK_RED_C000 = '#F44336';
export const FEEDBACK_RED_C100 = '#FBE6E4';
export const FEEDBACK_WHITE_C000 = '#FFFFFF';
