export const PLACES__ADD_REQUEST = 'PLACES__ADD_REQUEST';
export const PLACES__DELETE_REQUEST = 'PLACES__DELETE_REQUEST';
export const PLACES__EDIT_REQUEST = 'PLACES__EDIT_REQUEST';
export const PLACES__UPDATE_REQUEST = 'PLACES__UPDATE_REQUEST';
export const PLACES__UPDATE_STATUS = 'PLACES__UPDATE_STATUS';
export const PLACES__SYNC = 'PLACES__SYNC';
export const PLACES__SYNC_SUCESS = 'PLACES__SYNC_SUCESS';
export const PLACES__RESET = 'PLACES__RESET';
export const PLACES__SEARCH = 'PLACES__SEARCH';
export const PLACES__SET_CURRENT = 'PLACES__SET_CURRENT';

export const STATUS = {
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  NEW: 'NEW',
  UPDATE: 'UPDATE'
}

export const clearForm = () => ({
  type: PLACES__RESET
})

export const syncPlaces = (uid) => ({
  type: PLACES__SYNC,
  uid
})

export const syncPlacesSuccess = (places) => ({
  type: PLACES__SYNC_SUCESS,
  places
})

export const searchPlaceResult = (result) => ({
  type: PLACES__SEARCH,
  result
})

export const addPlace = (payload) => ({
  type: PLACES__ADD_REQUEST,
  ...payload
})

export const setCurrentPlace = (current) => ({
  type: PLACES__SET_CURRENT,
  current
})

export const editPlace = (id) => ({
  type: PLACES__EDIT_REQUEST,
  status: STATUS.UPDATE,
  id
})

export const updatePlace = (payload) => ({
  type: PLACES__UPDATE_REQUEST,
  status: STATUS.PROCESSING,
  payload
})

export const deletePlace = (id, uid) => ({
  type: PLACES__DELETE_REQUEST,
  id,
  uid
})

export const updateStatus = (method, status) => ({
  type: PLACES__UPDATE_STATUS,
  method,
  status
})
