import {
  STORAGE__SEND_FILE_STATUS
} from './actions';

const initialState = {
  loading: false,
  progress: 0,
  url: null,
}

export default function storageReducer(state = initialState, action = {}) {
  switch (action.type) {

    case STORAGE__SEND_FILE_STATUS:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}
