import {
  PLACES__ADD_REQUEST,
  PLACES__EDIT_REQUEST,
  PLACES__UPDATE_REQUEST,
  PLACES__DELETE_REQUEST,
  PLACES__UPDATE_STATUS,
  PLACES__SET_CURRENT,
  PLACES__SYNC,
  PLACES__SYNC_SUCESS,
  PLACES__RESET,
  PLACES__SEARCH
} from './actions';

// const initCurrent = {
//   FullName: '',
//   Lat: '',
//   Long: ''
// }

const initState = {
  current: {},
  docs: null,
  loading: false,
  processing: false,
  status: {},
  search: {}
};


const getCurrentPlace = (state, action) => {
  const { docs } = state
  const { id, status } = action
  const current = docs.find((doc) => doc.id === id)
  return {
    ...state,
    current,
    status
  };
}

export default function authReducer(state = initState, action) {
  switch (action.type) {

  case PLACES__SYNC:
    return {
      ...state,
      loading: true
    };

  case PLACES__SYNC_SUCESS:
    return {
      ...state,
      docs: action.places,
      loading: false
    };

  case PLACES__SEARCH:
    return {
      ...state,
      search: action.result
    };

  case PLACES__SET_CURRENT:
    return {
      ...state,
      current: action.current
    };

  case PLACES__RESET:
    return {
      ...state,
      current: {},
      status: {}
    };

  case PLACES__ADD_REQUEST:
    return {
      ...state,
      processing: true
    };

  case PLACES__DELETE_REQUEST:
    return {
      ...state,
      processing: true
    };

  case PLACES__EDIT_REQUEST:
    return getCurrentPlace(state, action);

  case PLACES__UPDATE_REQUEST:
    return {
      ...state,
      processing: true
    };

  case PLACES__UPDATE_STATUS:
    return {
      ...state,
      processing: false,
      status: action.status,
      lastTask: {
        method: action.method,
        status: action.status,
      }
    };

  default:
    return state;
  }
}
