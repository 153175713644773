export const USER_TYPE_ADMIN = 'ADMIN';
// GUEST is a front-end only value, which is never returned by the backend
export const USER_TYPE_GUEST = 'GUEST';
export const USER_TYPE_USER = 'USER';
export const USER_TYPE_STAFF = 'STAFF';

export default {
  ADMIN: USER_TYPE_ADMIN,
  GUEST: USER_TYPE_GUEST,
  USER: USER_TYPE_USER,
  STAFF: USER_TYPE_STAFF
};
