import {
  LOGIN__REQUEST,
  LOGIN__SUCCESS,
  LOGIN__FAILURE,
  LOGOUT__REQUEST,
  REGISTER_REQUEST,
  REGISTER__SUCCESS,
  REGISTER__FAILURE,
  AUTH__UPDATE_PROFILE_SUCCESS
} from './actions';

const initState = {
  loading: false,
  loggedIn: false,
  user: null
};

const parseProfileUpdate = (state, action) => {
  const { displayName, photoURL } = action;
  const update = {}

  if (displayName) update.displayName = displayName;
  if (photoURL) update.photoURL = photoURL;

  return {
    ...state,
    user: {
      ...state.user,
      ...update
    }
  };
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
  case AUTH__UPDATE_PROFILE_SUCCESS:
    return parseProfileUpdate(state, action);

  case LOGIN__REQUEST:
    return {
      ...state,
      loading: true
    };

  case LOGIN__SUCCESS:
    return {
      ...state,
      loggedIn: true,
      loading: false,
      user: action.user
    };

  case LOGIN__FAILURE:
    return {
      ...initState,
      error: action.error
    };

  case LOGOUT__REQUEST:
    return initState;

  case REGISTER_REQUEST:
    return {
      ...state,
      loading: true
    };

  case REGISTER__SUCCESS:
    return {
      ...state,
      loading: false
    };

  case REGISTER__FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      method: action.method
    };

  default:
    return state;
  }
}
