import { USER_TYPE_GUEST, USER_TYPE_USER } from '@lifetrip/shared/constants/user-types';
import { useUserContext } from '@components/UserDataContext';

import { stringify } from 'querystring';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router';

/**
 * @internal
 */
export const getPathnameForUserType = (path, userType) => {
  if (!isObject(path)) {
    return path;
  }

  if (path[userType]) {
    return path[userType];
  }

  throw new Error(
    `useGetPath: error finding path for userType (user type: ${userType}, path: ${JSON.stringify(
      path
    )}`
  );
};

/**
 * Returns a function which transforms a path based on userType and parameters
 *
 * @return function
 */
const useGetPath = () => {
  const currentRouteParams = useParams();
  const { userType } = useUserContext();

  return useCallback(
    (to) => {
      let extra;
      let params;
      let pathname;
      let search;

      if (isObject(to)) {
        if ('hash' in to || 'pathname' in to || 'search' in to) {
          ({ params, pathname, search, ...extra } = to);

          pathname = getPathnameForUserType(pathname, userType);
          search = !search || isString(search) ? search : stringify(search);
        } else {
          pathname = getPathnameForUserType(to, userType);
        }
      } else if (isString(to)) {
        pathname = getPathnameForUserType(to, userType);
      } else {
        throw Error(
          'Invalid URI. `to` must either be an object with a combination of `hash`, `pathname` or `search` specified or as a string pathname.'
        );
      }

      const isInternalPath = /^\/(?!\/)/.test(pathname);
      if (isInternalPath) {
        pathname = generatePath(pathname, { ...currentRouteParams, ...params });
      }

      return {
        pathname,
        search,
        ...extra
      };
    },
    [currentRouteParams, userType]
  );
};

export const PATHNAME_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    [USER_TYPE_GUEST]: PropTypes.string,
    [USER_TYPE_USER]: PropTypes.string
  })
]);

export default useGetPath;
