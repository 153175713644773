import isEmpty from 'lodash/isEmpty';

const generateSources = sources =>
  Object.entries(sources)
    .map(([format, path]) => `url("${path}") format("${format}")`)
    .join(', ');

/**
 * CSS for a @font-face declaration.
 *
 * @example
 *
 * // Styles as object basic usage
 * const styles = {
 *    ...fontFace({
 *      'family': 'Flourish',
 *      'sources': {
 *        'format': 'path/to/file'
 *      }
 *    })
 * }
 *
 * // styled-components basic usage
 * injectGlobal`${
 *   fontFace({
 *     'family': 'Flourish'
 *      'sources': {
 *        'format': 'path/to/file'
 *      }
 *   }
 * )}`
 *
 * // CSS as JS Output
 *
 * '@font-face': {
 *   'fontFamily': 'Flourish',
 *   'src': url("path/to/file.woff2") format("woff2"), url("path/to/file.woff") format("woff")',
 * }
 */
export default ({
  display,
  family,
  sources,
  stretch,
  style,
  variant,
  weight
}) => {
  if (isEmpty(sources)) {
    throw new Error(
      'fontFace expects the sources of the font file(s) to be defined.'
    );
  }

  return `
    @font-face {
      font-display: ${display};
      font-family: ${family};
      font-stretch: ${stretch};
      font-style: ${style};
      font-variant: ${variant};
      font-weight: ${weight};
      src: ${generateSources(sources)};
    }
  `;
};
