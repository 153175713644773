import { PATHNAME_PROP_TYPE } from '@lifetrip/shared/hooks/use-get-path';
import PropTypes from 'prop-types';

/*
  TODO -- add Anchor ...
*/

export const contentPropTypesSchema = {
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.oneOfType([
    PATHNAME_PROP_TYPE,
    PropTypes.shape({
      hash: PropTypes.string,
      params: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      pathname: PATHNAME_PROP_TYPE,
      search: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
      ])
    })
  ])
};
