export const STORAGE__SEND_FILE_STATUS = 'STORAGE__SEND_FILE_STATUS';
export const STORAGE__SET_FILE_URL = 'STORAGE__SET_FILE_URL';

export const setFileURL = url => ({
  type: STORAGE__SET_FILE_URL,
  url,
})

export const sendFile = () => ({
  type: STORAGE__SEND_FILE_STATUS,
})
