import { BASE_THEME_MUI, BASE_THEME_STYLED } from '@themes/constants/base-theme';
import {
  LIFETRIP_BRAND_C000,
  LIFETRIP_BRAND_C100,
  LIFETRIP_BRAND_C200,
  LIFETRIP_BRAND_C300,
  LIFETRIP_BRAND_C400,
  LIFETRIP_BRAND_C500,
  LIFETRIP_BRAND_C600,
  LIFETRIP_BRAND_C700,
  LIFETRIP_BRAND_C800
} from '@themes/constants/colors';
import mixColor from '@themes/utils/util-mix-color';
import { createMuiTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';

const styled = merge({}, BASE_THEME_STYLED, {
  colors: {
    brand: [
      LIFETRIP_BRAND_C000,
      LIFETRIP_BRAND_C100,
      LIFETRIP_BRAND_C200,
      LIFETRIP_BRAND_C300,
      LIFETRIP_BRAND_C400,
      LIFETRIP_BRAND_C500,
      LIFETRIP_BRAND_C600,
      LIFETRIP_BRAND_C700,
      LIFETRIP_BRAND_C800
    ]
  }
});

export default {
  Mui: createMuiTheme(
    merge({}, styled, BASE_THEME_MUI, {
      palette: {
        primary: {
          dark: mixColor(LIFETRIP_BRAND_C300, -0.1),
          light: mixColor(LIFETRIP_BRAND_C300, 0.1),
          main: LIFETRIP_BRAND_C000
        },
        secondary: {
          dark: mixColor(LIFETRIP_BRAND_C200, -0.1),
          light: mixColor(LIFETRIP_BRAND_C200, 0.1),
          main: LIFETRIP_BRAND_C200
        }
      }
    })
  ),
  Styled: styled
};
