/* Color tinting utility.
 * @param {string} color Color to tint, in hexadecimal.
 * @param {float} opacity A float from -1 to 1; negative values darken and positive values lighten.
 */
export default (color, opacity) => {
  const f = parseInt(color.slice(1), 16);
  const t = opacity < 0 ? 0 : 255;
  const p = opacity < 0 ? opacity * -1 : opacity;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;

  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};
