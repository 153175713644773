import {
  all,
  call,
  takeEvery,
  put
} from 'redux-saga/effects';

import {
  updateRoleSuccess,
  ADMIN__ROLE_UPDATE
} from './actions';

import { rsf } from '@lifetrip/services/firebase';


/**
 * Change Role
 */
function* changeRoleSaga(email) {
  console.log('saga.. make admin', email)
  const response = []

  // assign the admin role on the server via functions
  const result = yield call(rsf.functions.call, ' addAdminRole', { email })
  console.log(`result: ${result}`)

  yield put(updateRoleSuccess(response));
}


export default function* rootSaga() {
  yield all([
    takeEvery(ADMIN__ROLE_UPDATE, changeRoleSaga)
  ]);
}
