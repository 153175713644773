export const BOTTOM_NAV__ITEM = 'BOTTOM_NAV__ITEM';
export const STATUS__LOADED = 'STATUS__LOADED';
export const MAPS__MINI_TOGGLE = 'MAPS__MINI_TOGGLE';
export const DRAWER__TOGGLE = 'DRAWER__TOGGLE';
export const MAPS__TOGGLE = 'MAPS__TOGGLE';
export const MAPS__VIEWPORT = 'MAPS__VIEWPORT';
export const MAPS__TOGGLE_TRANSITION = 'MAPS__TOGGLE_TRANSITION';


export const setStatus = () => ({
  type: STATUS__LOADED
});

export const toggleDrawer = () => ({
  type: DRAWER__TOGGLE
});

export const toggleMaps = () => ({
  type: MAPS__TOGGLE
});

export const toggleMapTransition = () => ({
  type: MAPS__TOGGLE_TRANSITION
});


export const setMapViewport = (place) => ({
  type: MAPS__VIEWPORT,
  place
});

export const toggleMiniMap = (showMiniMap) => ({
  type: MAPS__MINI_TOGGLE,
  showMiniMap
});

export const setBottomNavItem = (bottomNavItem) => ({
  type: BOTTOM_NAV__ITEM,
  bottomNavItem
});
