import React, { useCallback } from "react";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Map from '@components/Maps/Mapbox';
import { addPlace } from '@store/places/actions';

const useStyles = makeStyles((theme) => ({
  map: {
    background: '#000',
    display: 'flex',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    transition: 'all 300ms ease-out'
  },
  miniMap: {
    height: '50vh',
    transition: 'all 300ms ease-in',
    zIndex: '10'
  }
}));


/**
 * Map Container
 */
const MapContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.Auth.user);
  const { search } = useSelector((state) => state.Places);
  const { showMiniMap } = useSelector((state) => state.App);

  const className = clsx({
    [classes.map]: true,
    [classes.miniMap]: showMiniMap
  })

  const handleOnSave = useCallback(
    () => {
      const payload = {
        ...search,
        uid
      };
      console.log(payload)
      dispatch(addPlace(payload));
    },
    [dispatch, search, uid]
  );

  return (
    <div className={className}>
      <Map saveSearchLocation={handleOnSave} />
    </div>
  )
}

export default MapContainer;
