import { contentPropTypesSchema } from '@components/native/Anchor';
import useGetPath from '@lifetrip/shared/hooks/use-get-path';
import { Redirect as ReactRouterRedirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Custom wrapper around react-router's Redirect component
 * @constructor
 */
const Redirect = ({ to, ...restProps }) => {
  const getPath = useGetPath();
  return <ReactRouterRedirect {...restProps} to={getPath(to)} />;
};

Redirect.displayName = 'Redirect';

Redirect.defaultProps = {};

Redirect.propTypes = {
  push: PropTypes.bool,
  to: contentPropTypesSchema.to.isRequired
};

export default Redirect;
