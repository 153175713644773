import UserDataContext from '@components/UserDataContext';
import { USER_TYPE_ADMIN, USER_TYPE_GUEST } from '@lifetrip/shared/constants/user-types';
import React from 'react';

const UserDataContextProvider = ({ children, value }) => {
  const isAdmin = value.userType === USER_TYPE_ADMIN;

  return (
    <UserDataContext.Provider
      value={{
        ...value,
        enabledProducts: value.enabledProducts || [],
        firmReferralEnabled: !!value.firmReferralEnabled,
        isAdmin,
        referralEnabled: !!value.referralEnabled,
        relatedAccountOwner: value.relatedAccountOwner || [],
        userType: value.userType || USER_TYPE_GUEST
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

UserDataContextProvider.displayName = 'UserDataContextProvider';

export default UserDataContextProvider;
