import {
  // Color,
  color,
  createGlobalStyle,
  getCssFromSystem
} from '@themes';

import {
  graphikMediumWebFontFace,
  graphikRegularWebFontFace
} from '@themes/constants/typography';

export default createGlobalStyle`
  ${graphikRegularWebFontFace}
  ${graphikMediumWebFontFace}
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  html {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.Graphik};
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    ${({ theme }) =>
    getCssFromSystem(theme, [color], {
      // bg: Color.greyscale.C800,
      // color: Color.greyscale.C000
    })};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: flex;
    height: 100%;
    text-rendering: geometricPrecision;
    width: 100%;
  }

  body.full {
    height: 100%;
  }

  #root {
    width: 100%;
  }

  main {
    /* overflow-y: scroll; */
    -webkit-overflow-scrolling: touch;
    flex: auto;
  }
`;
