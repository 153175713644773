import castArray from 'lodash/castArray';
import merge from 'lodash/merge';

const getCssFromSystem = (theme, interpolations, props) => {
  const allStyles = {};
  interpolations.forEach(
    (interpolationFn) =>
      castArray(interpolationFn({ ...props, theme })).forEach((style) => merge(allStyles, style)),
    {}
  );
  return allStyles;
};

export default getCssFromSystem;
