import React from 'react';
import Router from '@components/Router';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
// import { createFirestoreInstance } from 'redux-firestore';
import {
  THEME_DEFAULT,
  ThemeProvider
} from '@themes';
import CssBaseline from '@material-ui/core/CssBaseline';
import GlobalStyle from '@themes/components/GlobalStyle';

// import firebase from '@lifetrip/services/firebase';
import reduxStore, { history } from '@lifetrip/redux/store';

const initialState = {};
const {
  store,
  //persistor
} = reduxStore(initialState);

// NOTE: react-redux-firebase is redundant if using redux-saga-firebase
// HOLD FOR NOW IN CASE redux-saga-firebase is insufficant for my needs

// react-redux-firebase config
// const rrfConfig = {
//   userProfile: 'users',
//   useFirestoreForProfile: true,
//   enableClaims: true,
//   enableLogging: true
// }

// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance
// }

const App = () => (
  <ThemeProvider theme={THEME_DEFAULT}>
    <CssBaseline />
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
      <Router history={history} />
      {/* </ReactReduxFirebaseProvider> */}
      {/* </PersistGate> */}
    </Provider>
    <GlobalStyle />
  </ThemeProvider>
);

export default App;
