import graphikMediumWebWoff from '@lifetrip/shared/static/fonts/Graphik-Web/Graphik-Medium-Web.woff';
import graphikMediumWebWoff2 from '@lifetrip/shared/static/fonts/Graphik-Web/Graphik-Medium-Web.woff2';
import graphikRegularWebWoff from '@lifetrip/shared/static/fonts/Graphik-Web/Graphik-Regular-Web.woff';
import graphikRegularWebWoff2 from '@lifetrip/shared/static/fonts/Graphik-Web/Graphik-Regular-Web.woff2';
import fontFace from '@lifetrip/shared/themes/utils/util-font-face';

export const FONT_SIZE_BASE = 16;

const defaults = {
  display: 'fallback',
  family: '"Graphik Web"',
  stretch: 'normal',
  style: 'normal',
  variant: 'normal'
};

/* eslint-disable sort-keys */

export const graphikMediumWebFontFace = fontFace({
  ...defaults,
  sources: {
    woff2: graphikMediumWebWoff2,
    woff: graphikMediumWebWoff
  },
  weight: 500
});

export const graphikRegularWebFontFace = fontFace({
  ...defaults,
  sources: {
    woff2: graphikRegularWebWoff2,
    woff: graphikRegularWebWoff
  },
  weight: 400
});

/* eslint-enable sort-keys */
