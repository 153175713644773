import PublicRouteBase from './Base';
import React from 'react';

const Default = ({ children }) => {
  return children;
};

const PublicRoute = (props) => {
  const { children, route } = props;
  // console.log('props', props);
  // console.log('route', route);

  return (
    <PublicRouteBase route={route}>
      <Default>{children}</Default>
    </PublicRouteBase>
  );
};

export default PublicRoute;
