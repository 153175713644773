import {
  ADMIN__ROLE_UPDATE,
  ADMIN__ROLE_UPDATE_SUCCESS

} from './actions';

const initState = {
  loading: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {

    case ADMIN__ROLE_UPDATE:
      return {
        ...state,
        loading: true
      };

    case ADMIN__ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        // users: action.users,
        loading: false
      };

    default:
      return state;
  }
}
