import {
  USER_TYPE_ADMIN,
  USER_TYPE_GUEST,
  USER_TYPE_USER
} from '@lifetrip/shared/constants/user-types';

const PATHS = {
  USER_HOME: {
    [USER_TYPE_ADMIN]: '/admin',
    [USER_TYPE_USER]: '/home',
    [USER_TYPE_GUEST]: '/about'
  },
  USER_LOGIN: {
    [USER_TYPE_ADMIN]: '/admin',
    [USER_TYPE_USER]: '/home',
    [USER_TYPE_GUEST]: '/login'
  },
  LOADING: {
    [USER_TYPE_ADMIN]: '/loading',
    [USER_TYPE_USER]: '/loading',
    [USER_TYPE_GUEST]: '/loading'
  }
};

export default PATHS;
