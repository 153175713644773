import { USER_TYPE_GUEST } from '@lifetrip/shared/constants/user-types';
import { createContext, useContext } from 'react';

export const DEFAULT_VALUE = {
  enabledProducts: [],
  personId: undefined,
  relatedAccountOwner: [],
  userId: undefined,
  userType: USER_TYPE_GUEST
};

const USER_DATA_CONTEXT = createContext(DEFAULT_VALUE);

export { default as UserDataContextProvider } from './Provider';

/**
 * @typedef UserDataObject
 * @property {Boolean} canSendNonFirmInvites - Indicates if the user can send invites to friends and family
 * @property {String} email - Email of the user
 * @property {[String]} enabledProducts - List of Flourish products user has access to
 * @property {Boolean} firmContentKey - Unique content key that identifies the firm. null if not firm associated
 * @property {Number} firmId - Unique id that identifies the firm. null if not firm associated
 * @property {String} firmName - Name of the firm. null if not firm associated
 * @property {Boolean} firmReferralEnabled - Indicates if the firm has referrals functionality enabled. null if not firm associated.
 * @property {String} firmType - Type of firm. null if not firm associated
 * @property {String} firstName - First name of the user
 * @property {Boolean} isAdvisor - Indicates if the user is an Advisor
 * @property {Boolean} isAdvisorOrExecutive - Indicates if the user is an Advisor or an Executive
 * @property {Boolean} isCommonBondUser - Indicates if the user's firm is CommonBond
 * @property {Boolean} isExecutive - Indicates if the user is an Executive
 * @property {Boolean} isInvestor - Indicates if the user is an Investor
 * @property {Number} personId - Person Id of the logged in user
 * @property {Boolean} referralEnabled - Indicates if the user has referrals functionality enabled
 * @property {Boolean} softAuthRequired - Indicates if the user needs to have an extra auth step setting the password
 * @property {Number} userId - User id of the logged in user
 * @property {String} userType - User type of the logged in user
 */

/**
 * Returns the data object of the user that is currently logged in.
 *
 * @return {UserDataObject}
 */
export const useUserContext = () => useContext(USER_DATA_CONTEXT);

export default USER_DATA_CONTEXT;
