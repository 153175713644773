import { createContext, useContext } from 'react';

/**
 * @typedef RouteConfigContext
 *
 * Route configuration from the route table
 *
 * @property {Boolean} exact
 * @property {Boolean} mfaRequired
 * @property {Object} metadata
 * @property {String} metadata.submitDestination
 * @property {String} metadata.stepupDestination
 * @property {String} metadata.title
 * @property {String} path
 * @property {Array} permissions
 */
export const DEFAULT_VALUE = {};

export const ROUTE_CONFIG_CONTEXT = createContext(DEFAULT_VALUE);

export default ROUTE_CONFIG_CONTEXT;

/**
 * Returns the route configuration of the current route that is rendered.
 *
 * @return {RouteConfigContext}
 */
export const useRouteConfigContext = () => useContext(ROUTE_CONFIG_CONTEXT);
