import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popup } from "react-map-gl";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1rem'
  },
  popUp: {
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    // height: '100px',
    maxWidth: '400px'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  controlBar: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    flex: 1
  },
  mapboxglPopupCloseButton: {
    fontSize: '2rem'
  }
  // TODO - mapboxgl-popup-close-button
}));

const MarkerLocationInfo = ({
  coordinates: {
    longitude,
    latitude
  },
  text,
  onClose,
  onSave
}) => {
  const classes = useStyles();

  return (
    <Popup
      offsetTop={-20}
      className={classes.root}
      tipSize={10}
      longitude={longitude}
      latitude={latitude}
      closeOnClick={false}
      closeButton={false}
    >
      <div className={classes.popUp}>

        <div className={classes.title}>
          {text}
        </div>
        <div className={classes.controlBar}>
          <button onClick={() => onSave()}>save</button>
          <button onClick={() => onClose(null)}>close</button>
        </div>

      </div>
    </Popup>
  )
}

export default MarkerLocationInfo;
