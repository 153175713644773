export const USERS__SYNC = 'USERS__SYNC';
export const USERS__SYNC_SUCCESS = 'USERS__SYNC_SUCCESS';

export const syncUsers = ({
  type: USERS__SYNC
});

export const syncUsersSuccess = (users) => ({
  type: USERS__SYNC_SUCCESS,
  users
});
