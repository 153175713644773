import RouteConfigContext from '@components/Router/RouteConfigContext';
import { Route } from 'react-router';
import React from 'react';

export const Base = ({ children, fromStateToken }) => {
  return children;
};

const PublicRouteBase = ({ children, route }) => {
  return (
    <Route {...route}>
      <RouteConfigContext.Provider value={route}>
        {/* <LocationListener /> */}
        <Base>{children}</Base>
      </RouteConfigContext.Provider>
    </Route>
  );
};

export default PublicRouteBase;
