import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from 'mapbox-gl';

import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as d3 from 'd3-ease';

import Geocoder from "react-map-gl-geocoder";
import MapGL, {
  GeolocateControl,
  FlyToInterpolator,
  LinearInterpolator,
  FullscreenControl,
  NavigationControl,
  ScaleControl
} from "react-map-gl";


import {
  setCurrentPlace,
  searchPlaceResult,
  syncPlaces,
  STATUS
} from '@store/places/actions';

import MarkerLocationInfo from './MarkerLocationInfo';
import Marker from './Marker'

import { setMapViewport } from '@lifetrip/redux/app/actions';

// Known bug in webpack re: mapbox-gl and code spliting
// https://stackoverflow.com/questions/65802002/mapbox-production-error-in-console-uncaught-referenceerror-y-is-not-defined
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const { REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN } = process.env;


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: '#000',
    display: 'flex',
    color: '#fff',
    justifyContent: 'center',
    width: '100%'
  },
  popUp: {
    color: '#000'
  },
  controlsContainer: {
    // width: '40px',
    right: '0',
    top: '20vh',
    position: 'absolute'
  }
}));


/**
 *
 */
const Map = ({
  saveSearchLocation
}) => {
  const classes = useStyles();
  const mapRef = useRef();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.Auth.user);

  const {
    mapViewport,
    // mapTransition,
    showMiniMap
  } = useSelector((state) => state.App);

  const {
    docs,
    loading,
    search,
    // status
  } = useSelector((state) => state.Places);

  useEffect(() => {
    if (!docs && !loading){
      dispatch(syncPlaces(uid));
    }
  });

  const [popupInfo, togglePopup] = useState(null);


  const geolocateStyle = {
    top: 20,
    right: 0,
  };

  const fullscreenControlStyle= {
    right: 10,
    top: 10,
    padding: '5px'
  };

  const navStyle = {};
  const scaleControlStyle = {};


  // const [resultMarker, setResultMarker] = useState({})
  const handleClear = useCallback(
    (event) => {
      if (popupInfo !== null) {
        console.log('handleClear...', popupInfo)
        togglePopup(null);
      }
    },
    [popupInfo]
  );

  const onSelectCity = useCallback((place) => {
    const { coordinates } = place;
    if (
      (mapViewport.longitude !== coordinates.longitude) &&
      (mapViewport.longitude !== coordinates.longitude))
    {
      dispatch(setMapViewport({
        coordinates,
        zoom: 10,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1000,
        transitionEasing: d3.easeCubic,
      }));

      dispatch(setCurrentPlace(place));
    }

  }, [dispatch, mapViewport]);

  const handleViewportChange = useCallback(
    (newViewport) => {
      if (
        (newViewport.latitude !== mapViewport.latitude &&
        newViewport.longitude !== mapViewport.longitude) ||
        newViewport.zoom !== mapViewport.zoom)
      {
        const { latitude, longitude, zoom } = newViewport;
        const place = {
          coordinates: {
            latitude,
            longitude
          },
          // transitionInterpolator: new LinearInterpolator(),
          // transitionDuration: 125,
          // transitionEasing: d3.easeCubicOut,
          zoom
        }
        dispatch(setMapViewport(place));
      }
    },
    [dispatch, mapViewport]
  );

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {

      const { latitude, longitude } = newViewport;
      const place = {
        coordinates: {
          latitude,
          longitude
        },
        zoom: 7,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: d3.easeCubicOut,
        transitionDuration: 500
      }

      dispatch(setMapViewport(place));
    },
    [dispatch]
  );

  const handleOnResult = useCallback(
    (event) => {
      try {
        const {
          result : {
            geometry : {
              coordinates
            }, context, id, text, place_name, place_type
          }
        } = event;

        const result = {
          coordinates: {
            latitude: coordinates[1],
            longitude: coordinates[0]
          },
          context,
          id,
          place_name,
          place_type,
          text
        };

        dispatch(searchPlaceResult(result));

      } catch (error) {
        // TODO - handle error
        console.log('error..', error)
      }
    },
    [dispatch]
  );



  const handleOnClickMarkerInfo = useCallback(
    () => {
      togglePopup(search);
    }, [search]
  );

  const handleOnSave = useCallback(
    () => {
      saveSearchLocation();
      togglePopup(null);
    },
    [saveSearchLocation]
  );

  // if (status === STATUS.DONE) {}

  return (
    <div className={classes.root}>
      <MapGL
        ref={mapRef}
        {...mapViewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN}
        onClick={handleClear}
        onTransitionEnd={() => {
          console.log('end')
        }}
      >
        {!showMiniMap &&
          <Geocoder
            clearOnBlur={true}
            marker={false}
            mapRef={mapRef}
            onResult={handleOnResult}
            onViewportChange={handleGeocoderViewportChange}
            mapboxApiAccessToken={REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN}
            position="top-left"
            onClear={handleClear}
          />
        }

        <div className={classes.controlsContainer}>
          <FullscreenControl style={fullscreenControlStyle} />
          {/* <GeolocateControl
            style={geolocateStyle}
            positionOptions={{enableHighAccuracy: true}}
            trackUserLocation={true}
          /> */}

          <NavigationControl style={navStyle} />
          {/* <ScaleControl style={scaleControlStyle} /> */}
        </div>

        {search && search.coordinates !== undefined &&
          <span onClick={handleOnClickMarkerInfo}>
            <Marker {...search} type="search" />
          </span>
        }

        {docs && docs.length > 0 &&
          docs.map((place) => (
            <span onClick={() => onSelectCity(place)} key={place.id}>
              <Marker {...place} type="default" />
            </span>
          ))
        }

        {popupInfo &&
          <MarkerLocationInfo {...popupInfo} onClose={togglePopup} onSave={handleOnSave} />
        }

      </MapGL>
    </div>
  )
};

export default Map;
