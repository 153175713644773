import { push } from 'connected-react-router';
import {
  all,
  take,
  takeEvery,
  put,
  fork,
  call
} from 'redux-saga/effects';

import {
  updateProfileRequestSuccess,
  loginSuccess,
  logoutSuccess,
  loginFailure,
  logoutFailure,
  registerFailure,
  LOGIN__REQUEST,
  LOGIN__SUCCESS,
  LOGOUT__REQUEST,
  REGISTER_REQUEST,
  AUTH__UPDATE_PROFILE
} from './actions';

import {
  STATUS__UNVERIFIED
} from '@constants/status';

import {
  setStatus
} from '@lifetrip/redux/app/actions';

import {rsf, USERS_COLLECTION } from '@lifetrip/services/firebase';

/**
 * Listener for user login and logout
 * auth triggers loginStatusWatcher
 */
function* loginStatusWatcher() {
  try {
    // events on this channel fire when the user logs in or logs out
    const channel = yield call(rsf.auth.channel)

    // always listening
    while (true) {
      const { user } = yield take(channel)
      if (user) {
        const idTokenResult = yield user.getIdTokenResult();
        // check for amdin claim.. token result is true or undefined
        user.admin = idTokenResult.claims.admin

        // // expire after 1 hour (time in miliseconds)
        // const expiresAt = Date.now() + 3600000;
        // localStorage.setItem('refreshToken', user.refreshToken);
        // localStorage.setItem('expiresAt', expiresAt);

        yield put(loginSuccess(user))
        yield put(setStatus())
      } else {
        yield put(logoutSuccess())
      }
    }
  }
  catch (error) {
    console.log('doh..', error)
  }
}

/**
 * Authenticate with Firebase
 * @param {*} email
 * @param {*} password
 */

function* login({ payload }) {
  try {
    const { email, password } = payload;
    yield call(rsf.auth.signInWithEmailAndPassword, email, password)
  }
  catch (error) {
    // TODO - LOG ERRORs
    yield put(loginFailure(error))
  }
}

/**
 * Logout
 */
function* logout() {
  try {
    yield localStorage.removeItem('refreshToken');
    yield call(rsf.auth.signOut)
  } catch (error) {
    yield put(logoutFailure(error))
  }
}

/**
 *
 * @param {*} param0
 */
function* register({ payload }) {
  try {
    const { userName, email, password } = payload;
    // create new user with auth
    const { user } = yield call(rsf.auth.createUserWithEmailAndPassword, email, password)
    const { creationTime, lastSignInTime } = user.metadata;
    // take new user id from auth and create an entry in the users collection
    const userData = {
      uid: user.uid,
      userName,
      email,
      creationTime,
      lastSignInTime,
      status: STATUS__UNVERIFIED
    };

    // create a doc using the user id as the document id
    const documentRef = `${USERS_COLLECTION}/${user.uid}`;
    yield call(rsf.firestore.setDocument, documentRef, userData);
    // NOTE: auth.createUserWithEmailAndPassword triggers a valid login auth which triggers loginStatusWatcher
    // TODO -- require email verifcation and forced login after successful register

  } catch (error) {
    // TODO - handle errors
    yield put(registerFailure({
      method: 'register',
      error
    }))
    // TODO - HANDLE REGISTER ERROR
    console.log('register err', error)
  }
}

 /**
 *
 * @param {*} payload
 */
function* updateProfile({ payload }) {
  try {
    const update = {}
    const { displayName, photoURL } = payload;

    if (displayName) update.displayName = displayName;
    if (photoURL) update.photoURL = photoURL;

    yield call(rsf.auth.updateProfile, update);
    yield put(updateProfileRequestSuccess(update));

  } catch (error) {
    console.error(error)
  }
};


/**
 * TODO - check for last viewed route
 **/
function* success() {
  yield put(push('/'));
}

export default function* rootSaga() {
  yield fork(loginStatusWatcher)
  yield all([
    takeEvery(LOGIN__REQUEST, login),
    takeEvery(LOGIN__SUCCESS, success),
    takeEvery(LOGOUT__REQUEST, logout),
    takeEvery(REGISTER_REQUEST, register),
    takeEvery(AUTH__UPDATE_PROFILE, updateProfile)
  ])
}
