import { all, call, takeEvery, put } from 'redux-saga/effects';
import { rsf, USERS_COLLECTION } from '@lifetrip/services/firebase';
import { syncUsersSuccess, USERS__SYNC } from './actions';

/**
 * Sync users
 */
function* syncUsersSaga() {
  const response = []
  const docs = yield call(rsf.firestore.getCollection, USERS_COLLECTION);

  docs.forEach(doc =>
    response.push({
      id: doc.id,
      ...doc.data(),
    }),
  )

  yield put(syncUsersSuccess(response));
}

export default function* rootSaga() {
  yield all([
    takeEvery(USERS__SYNC, syncUsersSaga)
  ]);
}
