import { FlyToInterpolator, LinearInterpolator } from 'react-map-gl';
import * as d3 from 'd3-ease';

import {
  BOTTOM_NAV__ITEM,
  DRAWER__TOGGLE,
  MAPS__TOGGLE,
  MAPS__MINI_TOGGLE,
  MAPS__TOGGLE_TRANSITION,
  MAPS__VIEWPORT,
  STATUS__LOADED
} from './actions';

const initState = {
  bottomNavItem: 'maps',
  loaded: false,
  openDrawer: false,
  showMaps: true,
  showMiniMap: false,
  mapViewport: {
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 9,
    transitionInterpolator: new LinearInterpolator(),
    transitionDuration: 20,
    transitionEasing: d3.easeLinear
  },
  mapTransition: false
};

export default function appReducer(state = initState, action) {

  switch (action.type) {

  case STATUS__LOADED:
    return {
      ...state,
      loaded: true
    };

  case DRAWER__TOGGLE:
    return {
      ...state,
      openDrawer: !state.openDrawer,
      showMiniMap: false
    };

  case MAPS__TOGGLE:
    return {
      ...state,
      bottomNavItem: null,
      showMaps: !state.showMaps,
      showMiniMap: false
    };

  case MAPS__MINI_TOGGLE:
    return {
      ...state,
      showMiniMap: action.showMiniMap
    };

  case MAPS__TOGGLE_TRANSITION:
    return {
      mapTransition: true
    };

  case MAPS__VIEWPORT:
    return {
      ...state,
      mapViewport: {
        ...state.mapViewport,
        latitude: !action.place ? null : action.place.coordinates.latitude,
        longitude: !action.place ? null :  action.place.coordinates.longitude,
        transitionDuration: action.place.transitionDuration,
        transitionEasing: action.place.transitionEasing,
        zoom: action.place.zoom || 9
      }
    };

  case BOTTOM_NAV__ITEM:
    return {
      ...state,
      bottomNavItem: action.bottomNavItem
    };

  default:
    return state;
  }
}
