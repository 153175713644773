import {
  USERS__SYNC,
  USERS__SYNC_SUCCESS
} from './actions';

const initState = {
  loading: false,
  loaded: false,
  users: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case USERS__SYNC:
      return {
        ...state,
        loading: true
      };

    case USERS__SYNC_SUCCESS:
      return {
        ...state,
        users: action.users,
        loading: false
      };

    default:
      return state;
  }
}
