import { useRouteConfigContext } from '@components/Router/RouteConfigContext';
import { useLocation, useRouteMatch } from 'react-router';
import React, { Component } from 'react';

class AsyncComponent extends Component {
  state = {
    Component: null
  };

  async componentDidMount() {
    const {
      match: { params = {} },
      route: { componentResolver }
    } = this.props;

    const { default: Component } = await componentResolver(params);

    this.setState({ Component });
  }

  render() {
    const { Component } = this.state;

    if (Component === null) {
      return null;
    }

    return Component ? <Component {...this.props} /> : null;
  }
}

// FIXME: Remove this layer once those hooks are used directly where they are needed.
export default () => (
  <AsyncComponent
    location={useLocation()}
    match={useRouteMatch()}
    route={useRouteConfigContext()}
  />
);
