import { all, fork } from 'redux-saga/effects';
import authSagas from './auth/saga';
import placesSagas from './places/saga';
import usersSagas from './users/saga';
import adminSagas from './admin/saga';

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(placesSagas),
    fork(usersSagas),
    fork(adminSagas)
  ]);
}
