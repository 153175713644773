import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

export const logger = createLogger();
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export default function reduxStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let middlewares = [thunk];

  const showLogger = false;
  if (process.env.NODE_ENV !== 'production' && showLogger) {
    middlewares.push(logger);
  }

  const store = createStore(
    rootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(...middlewares, thunk, sagaMiddleware, routerMiddleware(history))
    )
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer(history));
    });
  }

  // const persistor = persistStore(store);

  // return { store, persistor };

  return { store };
}
