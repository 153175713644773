import React, { Fragment } from 'react';
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import MapContainer from '@containers/MapContainer';
import routes from './routes';

const Router = ({ history }) => {
  const { loaded } = useSelector((state) => state.App);
  const { loggedIn } = useSelector((state) => state.Auth);

  return (
    <Fragment>
      <ConnectedRouter history={history}>
        <Switch>
          {routes.map(({ component: Component, type: RouteComponent, ...route }) => (
            <RouteComponent {...route} key={route.path} route={route}>
              <Component />
            </RouteComponent>
          ))}
        </Switch>
      </ConnectedRouter>
      {loaded && loggedIn &&
        <MapContainer />
      }
    </Fragment>
  )
};

export default Router;
